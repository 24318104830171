/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-23 21:50:54
 * @modify date 2022-03-23 21:50:54
 */

import {toNumber, toString} from 'lodash'
import { types } from "mobx-state-tree"
import { DefectModel } from "models/defect"

export const greenGradingProps = {
  id: types.maybeNull(types.number),
  color: types.optional(types.string, ''),
  weight: types.optional(types.number, 0),
  smell: types.optional(types.string, ''),
  author: types.optional(types.string, ''),
  greenGradingTypeId: types.maybeNull(types.number),
  createdAt: types.optional(types.string, ''),
  defects: types.optional(types.array(
    types.snapshotProcessor(DefectModel, {
      preProcessor: (sn: any) => {
        return {
          ...sn,
          id: toString(sn.id),
          count: toNumber(sn.count) || 0,
        }
      }
    })), []),
  roastColor: types.maybeNull(types.string),
  ovenTest: types.maybeNull(types.string),
  otaTest: types.maybeNull(types.string),
  glyphosateTest: types.maybeNull(types.string),
  size10: types.maybeNull(types.number),
  size11: types.maybeNull(types.number),
  size12: types.maybeNull(types.number),
  size13: types.maybeNull(types.number),
  size14: types.maybeNull(types.number),
  size15: types.maybeNull(types.number),
  size16: types.maybeNull(types.number),
  size17: types.maybeNull(types.number),
  size18: types.maybeNull(types.number),
  size19: types.maybeNull(types.number),
  size20: types.maybeNull(types.number),
  total: types.maybeNull(types.string),
  screenSize: types.maybeNull(types.number),
  sampleName: types.maybeNull(types.string),
  sampleUniqueNumber: types.maybeNull(types.string),
  greenGradingTypeName: types.maybeNull(types.string),
  density: types.maybeNull(types.union(types.string, types.number)),
  moisture: types.maybeNull(types.union(types.string, types.number)),
  waterActivity: types.maybeNull(types.union(types.string, types.number)),
  picture:  types.maybeNull(types.union(types.string, types.number)),
  picture2:  types.maybeNull(types.union(types.string, types.number)),
  quakerNumber: types.maybeNull(types.number),
}
