import React, {useState} from "react";
import {cloneDeep} from "lodash";
import {useTranslation} from "react-i18next";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Box, Button, Chip, Stack, Typography} from "@mui/material";

import {fulfillmentTypes} from "constants/form";
import {BasicTable, FormInput, ModalWrapper} from "components";
import {optionsTranslation} from "utils";

import {useStyles} from "./shipment-table.styles";
import {Add} from "@mui/icons-material";

const columnHelper = createColumnHelper<any>()

export const ShipmentTable = (props: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { groupSamplesToBeShipment, group, onSubmitEmail } = props

  const [emails, setEmails] = useState('')
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const data = cloneDeep(groupSamplesToBeShipment[group])
  const fulfilmentType = data[0].sampleFulfillmentType
  const sampleLocation = data[0].sampleLocation
  const thirdPartyEmail = data[0].thirdPartyEmail

  const renderValue = (info) => {
    if (info.renderValue()) return info.renderValue()

    return <Box textAlign="center">-</Box>
  }

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('shipment.table.type')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue(), true),
    }),
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: () => <Box textAlign="left">{t('shipment.table.sampleName')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.warehouseReference', {
      id: 'sample.warehouseReference',
      header: () => <Box textAlign="left">{t('shipment.table.warehouse')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.cargoNumber', {
      id: 'sample.cargoNumber',
      header: () => <Box textAlign="left">{t('shipment.table.cargo')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.customer', {
      id: 'sample.customer',
      header: () => <Box textAlign="left">{t('shipment.table.customer')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.purchaseContractReference', {
      id: 'sample.purchaseContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.salesContractReference', {
      id: 'sample.salesContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.salesContract')}</Box>,
      cell: renderValue,
    }),
  ]

  const shipmentTable = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Box className={classes.wrapper}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={thirdPartyEmail ? 2 : 1}>
        <Typography variant='h6'>
          {group === fulfillmentTypes[0] ?
            t('shipment.localShipment') :
            `${t('shipment.thirdPartyShipment')} (${group})`
          }
        </Typography>
        {group !== fulfillmentTypes[0] &&
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenEmailModal(true)}
          >
            {t("shipment.button.addEmail")}
          </Button>
        }
      </Stack>
      {thirdPartyEmail && (
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <Typography>{t('shipment.thirdPartyEmail')}:</Typography>
          {thirdPartyEmail.split(',').map(email => (
            <Chip key={email} label={email.trim()} color="primary" />
          ))}
        </Stack>
      )}

      <BasicTable table={shipmentTable} />

      <ModalWrapper open={openEmailModal} maxWidth='sm'>
        <Box>
          <Typography variant="h5" textAlign="center">{t('shipment.modal.addEmail')}</Typography>
          <Typography variant="body2" textAlign="center">{t('shipment.modal.addEmailDescription')}</Typography>

          <FormInput
            label={t('shipment.modal.email')}
            textFieldProps={{
              helperText: t('shipment.modal.helperEmailText'),
              value: emails,
              onChange: (event) => setEmails(event.target.value)
            }}
          />

          <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenEmailModal(false)
                onSubmitEmail(emails, fulfilmentType, sampleLocation)
              }}
            >
              {t(`shipment.button.submit`)}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenEmailModal(false)}
            >
              {t("common.cancel")}
            </Button>
          </Stack>
        </Box>
      </ModalWrapper>
    </Box>
  )
}
