/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:53
 * @modify date 2022-04-13 20:27:17
 */

import React from 'react'
import {chain, has, find, clone, includes, pull, remove, debounce} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  Box,
  Container,
  Button,
  Collapse,
  Typography,
  Divider,
  Tooltip,
  SvgIcon,
  Stack,
  Menu,
  MenuItem,
  AppBar, Toolbar
} from '@mui/material'
import {
  CompareArrows,
  ExpandLess,
  ExpandMore,
  KeyboardArrowDown,
  SearchSharp,
  SystemUpdateAlt
} from '@mui/icons-material'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import {IconAddCuppingSession, IconReviewSamples, IconShare, IconShippingFast} from 'assets/images'
import { globalAction, validateEmail, pointerMessages, countryName } from 'utils'
import { HeaderTitle, ModalWrapper, FormInput, MenuIcon, Pagination, FilterChip } from 'components'

import {AdvanceSearch, SampleTable, SampleItemMobile} from './components'

export const Samples: React.FC = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { search } = useLocation()
  const {
    reviewSampleStore: { search: { name, generalSearch, setValue: setSearchValue, reset: resetSearch },
      share: { title, to, message, setValue: setShareValue },
      searchValues, records, getSamples, shareSamples, samples, totalPage, page,
      setCuppingSamples, setValue: setReviewSampleValue,
    },
    sampleShipmentStore: { setSamplesToBeShipment, getNewSamples },
    compareStore: { setValue: setCompareValue },
    masterSampleStore: { exportMasterSample },
    notificationStore
  } = useStores()

  const homeLink = findRoute('home')
  const compareLInk = findRoute('compare')
  const [expanded, setExpanded] = React.useState(false)
  const [openShare, setOpenShare] = React.useState(false)
  const [ids, setIds] = React.useState<String[]>([])
  const [isReview, setIsReview] = React.useState(true)
  const [disableCompare, setDisableCompare] = React.useState(true)
  const [cuppingProtocols, setCuppingProtocols] = React.useState<String[]>([])
  const [shareErrors, setShareErrors] = React.useState<any>({})
  const [isMobile, setIsMobile] = React.useState(false)
  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [openShareReport, setOpenShareReport] = React.useState(false)
  const [shareReportIds, setShareReportIds] = React.useState("")
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])
  const [reservedSamples, setReservedSamples] = React.useState<any[]>([])
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)
  const [anchorNewSample, setAnchorNewSample] = React.useState<null | HTMLElement>(null)
  const openNewSampleMenu = Boolean(anchorNewSample)

  const onExport = () => globalAction(notificationStore, { action: async() => await exportMasterSample(ids) })

  const onCheck = (selectedSamples) => {
    let newIds: String[] = []
    let newCuppingProtocols: String[] = []
    let newReservedSamples: any[] = []

    selectedSamples.map(sample => {
      newIds.push(sample.id)
      newCuppingProtocols.push(sample.cuppingProtocol)
      newReservedSamples.push(sample)
    })

    setIds(newIds)
    setReservedSamples(newReservedSamples)
    setCuppingProtocols(newCuppingProtocols)
  }

  const onCheckMobile = (id : string) => {
    const { cuppingProtocol } = find(samples, ['id', id])

    const newIds = clone(ids)
    const newCuppingProtocols = clone(cuppingProtocols)

    if (includes(ids, id)) {
      pull(newIds, id)

      const index = newCuppingProtocols.indexOf(cuppingProtocol)
      if (index >= 0) newCuppingProtocols.splice(index, 1)

      remove(reservedSamples,(data) => data.id === id)

    } else {
      newIds.push(id)
      newCuppingProtocols.push(cuppingProtocol)
      reservedSamples.push(find(samples, ['id', id]))
    }

    setIds(newIds)
    setCuppingProtocols(newCuppingProtocols)
  }


  const renderMobile = () => {
    return(
      samples.map((sample) =>
        <SampleItemMobile
          {...sample}
          ids={ids}
          isReview={isReview}
          onCheck={() => onCheckMobile(sample.id)}
          onGetSamples={onGetSamples}
        />

      )
    )
  }

  const onRemoveSelectedSample = (sample) => {
    onCheck(reservedSamples.filter(s => s.id !== sample.id))
  }

  const filterLabel = (sample) => {
    return `${sample.name}${sample.countryCode ? `; ${countryName(sample.countryCode)}` : ''}${sample.processName ? `; ${sample.processName}` : ''}`
  }

  const onShareSamples = () => {
    setShareErrors({})
    const validateEmails = chain(to).split(',')
                            .map(email => { return validateEmail(email.replace(/\s/g, ""))})
                            .every(Boolean).value()

    if (!validateEmails) {
      setShareErrors({ "email": [{ message: t('sample.error.emailIsInvalid') }] })
      return
    }

    setShareValue('samplesToShare', ids.join(','))

    globalAction(notificationStore, {
      action: shareSamples,
      afterAction: () => {
        setIds([])
        setOpenShare(false)
      }
    })
  }

  const onGetSamples = React.useCallback(
    debounce(() => {
      globalAction(notificationStore, {
        action: async () => await getSamples(params.token)
      })
    }, 300), []
  )

  const onCompareSample = async () => {
    setCompareValue('samples', [])
    setCompareValue('ids', ids)
    navigate(compareLInk)
  }

  const onSetLayout = () => {
    if(window.innerWidth < 900) {
      setIsMobile(true)
      return
    }

    setIsMobile(false)
  }

  const handleChangeCategory = () => {
    temporaryListSearch.map(item =>
      Object.keys(searchValues).forEach(k => {
        if(k !== item )
        setTemporaryListSearch(current =>
          current.filter(data => {
            return data !== item;
          }),
        );
      })
    )
  }

  const renderButtonsMobile = () => {
    return(
      <Box sx={{mr: 2}}>
       <Button
          size="large"
          variant="contained"
          sx={{ ml: 1 }}
          onClick={(event) => setAnchorMenu(event.currentTarget)}
          endIcon= {openMenu ? <ExpandLess /> : <ExpandMore />}
          fullWidth
        >
         {t("sample.samples.action")}
        </Button>
        <Menu
          anchorEl={anchorMenu}
          open={openMenu}
          onClose={() => setAnchorMenu(null)}
          onClick={() => setAnchorMenu(null)}
          PaperProps={{
            style: {
                 width: "92%",
                 maxWidth: "92%",
               }
          }}
        >
        <MenuItem onClick={() => navigate(`/samples/new`)}>
          <MenuIcon>
            <SvgIcon component={IconReviewSamples} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newSample')}
        </MenuItem>

        <MenuItem onClick={() => navigate('/multiple-samples')}>
          <MenuIcon>
            <SvgIcon component={IconReviewSamples} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newMultipleSample')}
        </MenuItem>
        <MenuItem disabled={reservedSamples.length === 0} onClick={() => {
          const newSamples = getNewSamples(reservedSamples)
          setSamplesToBeShipment(newSamples)
          navigate(`/sample-shipments/new`)
          }}
        >
          <MenuIcon>
            <SvgIcon component={IconShippingFast} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newShipment')}
        </MenuItem>
        <MenuItem
          disabled={reservedSamples.length === 0}
          onClick={() => {
            setCuppingSamples(reservedSamples)
            navigate(`/cupping-sessions/new`)
          }}
        >
          <MenuIcon>
            <SvgIcon component={IconAddCuppingSession} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newCuppingSession')}
        </MenuItem>
        <MenuItem disabled={disableCompare}  onClick={onCompareSample}>
          <MenuIcon>
            <SvgIcon component={CompareArrows} inheritViewBox />
          </MenuIcon>
          {t('sample.compare')}
        </MenuItem>
        <MenuItem disabled={ids.length < 1} onClick={() => setOpenShare(true)}>
          <MenuIcon>
            <SvgIcon component={IconShare} inheritViewBox />
          </MenuIcon>
          {t('sample.share')}
        </MenuItem>
        <MenuItem onClick={onExport}>
          <MenuIcon>
            <SvgIcon component={SystemUpdateAlt} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.downloadToCsv')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setExpanded(!expanded)
            handleChangeCategory()
          }}>
          <MenuIcon>
            <SvgIcon component={SearchSharp} inheritViewBox />
          </MenuIcon>
          {t('sample.advanceSearch')}
        </MenuItem>
        </Menu>
      </Box>
    )
  }

  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  React.useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search).get('search')

      setReviewSampleValue('page', 1)
      setSearchValue('name', query)
      setSearchValue('producerName', query)
      setSearchValue('processName', query)
      setSearchValue('supplierName', query)
      setSearchValue('generalSearch', true)
      setSearchValue('sampleUniqueNumber', query)
      setSearchValue('customer', query)
      setSearchValue('purchaseContractReference', query)
      setSearchValue('salesContractReference', query)
      onGetSamples()
    }
  }, [search])

  React.useEffect(() => {
    if (params.token) setIsReview(false)

    if (!search) {
      resetSearch()
    }

    onGetSamples()
  }, [])

  React.useLayoutEffect(() => {
    setShareValue('message', t('sample.shareSampleText'))
    window.addEventListener('resize', onSetLayout)

    onSetLayout()
    return () => window.removeEventListener("resize", onSetLayout)
  }, [])

  React.useEffect(() => {
    onGetSamples()
  }, [page])

  return (
    <Box sx={{pb: {xs: 7, md: 0}}}>
      {isMobile ?
        <HeaderTitle
         title={t('menu.reviewSamples')}
         {...(isReview && {
           breadcrumb: t('common.backTo', { menu: t('menu.home')}),
           backUrl: homeLink,
         })}
         {...(generalSearch && {subtitle: t('sample.showingResult', {total: records, query: name})})}
        /> :
        <HeaderTitle
          title={t('menu.reviewSamples')}
          {...(isReview && {
            breadcrumb: t('common.backTo', { menu: t('menu.home')}),
            backUrl: homeLink,
          })}
          {...(generalSearch && {subtitle: t('sample.showingResult', {total: records, query: name})})}
        />
      }
      <Container>
        {reservedSamples.length > 0 && (
          <Stack direction="row" spacing={1} mb={2} useFlexGap flexWrap="wrap">
            {reservedSamples.map(s => (
              <FilterChip
                key={s.id}
                label={filterLabel(s)}
                onRemove={() => onRemoveSelectedSample(s)}
              />
            ))}

            <Button
              variant="text"
              size="small"
              color="error"
              onClick={() => onCheck([])}
              sx={{px: 1}}
            >
              {t('common.clearFilter')}
            </Button>
          </Stack>
        )}
      </Container>
      {(isReview && isMobile) && renderButtonsMobile()}
      {(isReview && !isMobile) &&
        <Container>
          <Stack direction="row" spacing={1} justifyContent="end">
            <Button
                size='large'
                variant='contained'
                color="secondary"
                onClick={(e) => setAnchorNewSample(e.currentTarget)}
                endIcon={openNewSampleMenu ? <ExpandLess /> : <ExpandMore />}
              >
                {t('sample.samples.newSample')}
            </Button>
            <Menu
              anchorEl={anchorNewSample}
              open={openNewSampleMenu}
              onClose={() => setAnchorNewSample(null)}
              onClick={() => setAnchorNewSample(null)}
            >
              <MenuItem onClick={() => navigate(`/samples/new`)}>
                {t('sample.samples.newSingleSample')}
              </MenuItem>
              <MenuItem onClick={() => navigate('/multiple-samples')}>
                {t('sample.samples.newMultipleSample')}
              </MenuItem>
            </Menu>

            <Button
                size='large'
                variant='contained'
                disabled={reservedSamples.length === 0}
                onClick={() => {
                  const newSamples = getNewSamples(reservedSamples)
                  setSamplesToBeShipment(newSamples)
                  navigate(`/sample-shipments/new`)
                }}
            >
               {t('sample.samples.newShipment')}
            </Button>
            <Button
                size='large'
                variant='contained'
                disabled={reservedSamples.length === 0}
                onClick={() => {
                  setCuppingSamples(reservedSamples)
                  navigate(`/cupping-sessions/new`)
                }}
            >
              {t('sample.samples.newCuppingSession')}
            </Button>
            <Tooltip title={disableCompare ? `${t('sample.theSameCuppingProtocolDescription')}` : ""} arrow>
              <span>
                <Button
                  size='large'
                  variant='contained'
                  disabled={disableCompare}
                  onClick={onCompareSample}
                  sx={{ height: '100%' }}
                >
                  {isMobile ? <CompareArrows/> : t('sample.compare')}
                </Button>
              </span>
            </Tooltip>
            <Button
              size='large'
              variant='contained'
              disabled={ids.length < 1}
              onClick={() => setOpenShare(true)}
            >
              {isMobile ? <SvgIcon component={IconShare} inheritViewBox/> : t('sample.share')}
            </Button>
            <Button
              size='large'
              variant='contained'
              onClick={onExport}
            >
              {t('sample.samples.downloadToCsv')}
            </Button>
            <Button
              size='large'
              variant='contained'
              onClick={() => {
                setExpanded(!expanded)
                handleChangeCategory()
              }}
            >
              {t('sample.advanceSearch')}
            </Button>
          </Stack>
        </Container>
      }

      <Container sx={{mb: 3}}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <AdvanceSearch
            isMobile={isMobile}
            expanded={expanded}
            temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
            temporarySearchAdvanceData = {searchAdvanceData}
            temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
            temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
            temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
            temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
            temporaryListSearch = {temporaryListSearch}
            addTemporarySearchAdvanceData={(item)=>{
              setSearchAdvanceData(item)
            }}
            addTemporaryDefectsDescriptors={(descriptors) => {
              setTemporaryDefectsDescriptors(descriptors)
            }}
            addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
              setTemporaryFlavorAndAftertasteDescriptors(descriptors)
            }}
            addTemporaryFragranceDescriptors={(descriptors) => {
              setTemporaryFragranceDescriptors(descriptors)
            }}
            addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
              setTemporaryBodyAndMouthfeelDescriptors(descriptors)
            }}
            addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
              setTemporaryAcidityAndSaltDescriptors(descriptors)
            }}
            addTemporaryListSearch={(data) => {
              setTemporaryListSearch(data)
            }}
            onSearch={() => {
              setSearchValue('generalSearch', false)
              setReviewSampleValue('page', 1)
              handleChangeCategory()
              onGetSamples()
            }}
            onCancel={() => {
              onGetSamples()
              setExpanded(false)
              window.scrollTo(0, 0)
              setTemporaryDefectsDescriptors([])
              setTemporaryFlavorAndAftertasteDescriptors([])
              setTemporaryFragranceDescriptors([])
              setTemporaryBodyAndMouthfeelDescriptors([])
              setTemporaryAcidityAndSaltDescriptors([])
              setTemporaryListSearch([])
            }}
          />
        </Collapse>
      </Container>

      {/* Tab */}
      <Container sx={{px: {xs: 0, md: 3}, mb: 5}}>
        {isMobile ?
          <>
            <Box sx={{mb: 5}}>
              {renderMobile()}
            </Box>
            <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, height: 60}}>
              <Toolbar>
              {totalPage > 0 && <Pagination page={page} count={totalPage} siblingCount={1} onChange={(e, p) => setReviewSampleValue('page', p)} /> }
              </Toolbar>
            </AppBar>
          </>
          :
          <SampleTable isReview={isReview} reservedSamples={reservedSamples}  onSelected={onCheck} onGetSamples={onGetSamples} />
        }
      </Container>

      <ModalWrapper
        maxWidth='sm'
        open={openShare}
        onClose={() => {
          setOpenShare(false);
          setOpenShareReport(false)}}
      >
        <Typography variant='h5' sx={{mb: 2}}>{t('sample.shareSampleList')}</Typography>
        <Divider />
        {openShareReport ?
          <FormInput
            label={t('sample.samplesToShare')}
            textFieldProps={{
              disabled: true,
              value: shareReportIds
            }}
          /> :
          <FormInput
            label={t('sample.samplesToShare')}
            textFieldProps={{
              disabled: true,
              value: ids.join(', ')
            }}
          />
        }

        <FormInput
          label={t('sample.title')}
          textFieldProps={{
            value: title,
            onChange: (e) => setShareValue('title', e.target.value)
          }}
        />
        <FormInput
          label={t('sample.to')}
          textFieldProps={{
            value: to,
            onChange: (e) => setShareValue('to', e.target.value),
            helperText: has(shareErrors, 'email') ? pointerMessages('email', shareErrors) : t('report.emailShareSampleDescription'),
            error: has(shareErrors, 'email')
          }}
        />
        <FormInput
          label={t('sample.emailMessage')}
          textFieldProps={{
            multiline: true,
            rows: 3,
            value: message,
            onChange: (e) => setShareValue('message', e.target.value)
          }}
        />

        <Button
          variant='contained'
          className='wide'
          sx={{mt: 3}}
          disabled={!title || !to || !message}
          onClick={onShareSamples}
        >
          {t('report.send')}
        </Button>
      </ModalWrapper>
    </Box>
  )
})

export default Samples
