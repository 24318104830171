import { Api } from "./api"

export class SampleTransactionApi extends Api {
  constructor() {
    super('sample_transactions', [
      "customer", "streetAddress", "recipient", "streetAddress2", "country", "state", "city", "zipCode", "isUrgentDelivery", "requestedByEmail",
      "recipientEmails", "traderEmails", "qualityControlEmails", "approvalEmails", "type", "sampleTransactionItemsAttributes", "label",
      "uniqueToken", "approvalStatus", "reason", "reasonJson", "qcReason", "thirdPartyEmail","thirdPartyName","fulfillmentStatus","archivedAt","isSubmitted",
      "courierName", "trackingNumber", "estimatedTimeArrival", 'emailTitle', 'emailRecipients', 'emailMessage','itemIds',
      'flavorDescriptors', 'defectsDescriptors', 'defects', 'totalScore', 'notes','streetAddress_2', 'sampleOrderUniqueToken', 'orderRemoteId', 'sampleOrderNumber'
    ])
  }
}
