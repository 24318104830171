import React from 'react'
import {upperFirst} from "lodash";
import {useGridApiContext} from "@mui/x-data-grid";
import {Autocomplete, TextField} from "@mui/material";

import {flatListDescriptors} from "utils";

export const DataGridDescriptors = (props) => {
	const { id, value, field, hasFocus, colDef } = props;
	const apiRef = useGridApiContext();

	const valueOptions = (flatListDescriptors(colDef.valueOptions) || []).map((item) => ({
		id: item.id,
		value: item.label,
		label: upperFirst(item.label),
	}));

	const handleValueChange = (event, item) => {
		const newValue = item.map(item => item.value);
		apiRef.current.setEditCellValue({ id, field, value: newValue });
	};

	return (
		<Autocomplete
			multiple
			fullWidth
			onChange={handleValueChange}
			options={valueOptions}
			getOptionLabel={(option) => option.label}
			isOptionEqualToValue={(o, v) => o.value == v.value}
			renderInput={(params) => (
				<TextField {...params} autoFocus={hasFocus} />
			)}
		/>
	)
}
