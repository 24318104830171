import React, {useRef, useState} from "react";
import moment from "moment";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {camelCase, isEmpty, range, toString, cloneDeep} from "lodash";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import {HelpOutlineOutlined} from "@mui/icons-material";

import {countries} from "utils"
import {specieses, sampleTypes, grades, printLabelSize } from 'constants/form'
import {ModalWrapper, BasicTable, LightTooltip} from "components";
import {colors} from "assets";

import {GenerateLabelProps} from "./generate-label.props";
import {PrintLabel} from "./print-label/print-label";

export const GenerateLabel: React.FC<GenerateLabelProps> = observer((props: GenerateLabelProps) => {
  const { open, onClose, index, onSubmit, samplesToBeShipment } = props
  const { t } = useTranslation()

  const [data, setData] = useState([{attribute: "", status: false, value: ""}]);
  const [paperSize, setPaperSize] = useState("letter")
  const refInputData = useRef('')
  const [disableCheckboxes, setDisableCheckboxes] = useState<any[]>([])
	const [openPrintLabel, setOpenPrintLabel] = useState(false)

  const dataSamples = samplesToBeShipment[index]['sample']

  const defaultData = [
    {
      attribute: "qrcode",
      status: true,
      value: samplesToBeShipment[index]["qrcode"]
    },
    {
      attribute: "sampleId",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["sampleUniqueNumber"],
      value: dataSamples["sampleUniqueNumber"]
    },
    {
      attribute: "description",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["description"],
      value: dataSamples["description"]
    },
    {
      attribute: "sampleType",
      status: paperSize === printLabelSize[1].key ? false : !!samplesToBeShipment[index]["sampleType"],
      value: samplesToBeShipment[index]["sampleType"]
    },
    {
      attribute: "species",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["species"],
      value: dataSamples["species"]
    },
    {
      attribute: "country",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["countryCode"],
      value: dataSamples["countryCode"]
    },
    {
      attribute: "sampleName",
      status: paperSize === printLabelSize[1].key ? false : !!samplesToBeShipment[index]["sampleName"],
      value: samplesToBeShipment[index]["sampleName"]
    },
    {
      attribute: "cropYear",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["cropYear"],
      value: dataSamples["cropYear"]
    },
    {
      attribute: "supplier",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["supplierName"],
      value: dataSamples["supplierName"]
    },
    {
      attribute: "purchaseContractReference",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["purchaseContractReference"],
      value: dataSamples["purchaseContractReference"]
    },
    {
      attribute: "client",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["customer"],
      value: dataSamples["customer"]
    },
    {
      attribute: "salesContractReference",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["salesContractReference"],
      value: dataSamples["salesContractReference"]
    },
    {
      attribute: "referenceNumber",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["referenceNumber"],
      value: dataSamples["referenceNumber"],
      values: dataSamples["referenceNumber"]
    },
    {
      attribute: "purchaseGrade",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["purchaseGrade"],
      value: dataSamples["purchaseGrade"],
      values: dataSamples["purchaseGrade"]
    },
    {
      attribute: "grade",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["grade"],
      value: dataSamples["grade"]
    },
    {
      attribute: "coffeeProcessing",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["processName"],
      value: dataSamples["processName"]
    },
    {
      attribute: "numberOfBags",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["numberOfBag"],
      value: dataSamples["numberOfBag"]
    },
    {
      attribute: "bagWeight",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["bagWeight"],
      value: dataSamples["bagWeight"]
    },
    {
      attribute: "shipmentMonth",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["shipmentDate"],
      value: dataSamples["shipmentDate"] ? moment(dataSamples["shipmentDate"]).format('L') : ''
    },
    {
      attribute: "varietals",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["varietalsTags"],
      value: dataSamples["varietalsTags"]
    },
    {
      attribute: "moisture",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["moisture"],
      value: dataSamples["moisture"]
    },
    {
      attribute: "waterActivity",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["waterActivity"],
      value: dataSamples["waterActivity"]
    },
    {
      attribute: "cuppingDate",
      status: paperSize === printLabelSize[1].key ? false : !!dataSamples["cuppingDate"],
      value: dataSamples["cuppingDate"]
    },
    {
      attribute: "paperSize",
      status: true,
      value: isEmpty(samplesToBeShipment[index].label) ? "letter" : JSON.parse(samplesToBeShipment[index].label)[23]["value"]
    },
  ]

  const columnHelper = createColumnHelper<any>()

  const getSymbol = (index) => {
    switch (index) {
      case "moisture":
        return "%"
      case "bagWeight":
        return "kg"
      default:
        return ""

    }

  }

  const renderEditStatus = (info) => {
    const { attribute } = info.row.original

    return(
      <Checkbox
        disabled={disableCheckboxes.includes(attribute)}
        checked={info.renderValue()}
        onChange={(event) => {
          refInputData.current = 'value' + attribute
          handlerUpdate(info, event.target.checked)
        }}
      />
    )
  }

  const renderEditData = (info) => {
    const key = 'value' + info.row.original.attribute
    switch (info.row.original.attribute) {
      case "qrcode":
        return(
          <Box></Box>
        )
      case "sampleType":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {sampleTypes.map(item =>
              <MenuItem key={item} value={item}>{t(`options.sampleType.${camelCase(item)}`)}</MenuItem>
            )}
          </Select>
        )
      case "country":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{height: 34}} />
            {countries.map(item =>
              <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
            )}
          </Select>
        )
      case "grade":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {grades.map(item =>
              <MenuItem key={item} value={item}>{t(`options.grades.${item}`)}</MenuItem>
            )}
          </Select>
        )
      case "cropYear":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{height: 34}} />
            {range((new Date()).getFullYear(), 2010).map(item =>
              <MenuItem key={item} value={toString(item)}>{item}</MenuItem>
            )}
          </Select>
        )
      case "species":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {specieses.map(item =>
              <MenuItem key={item} value={item}>{t(`options.species.${item}`)}</MenuItem>
            )}
          </Select>
        )
      case "numberOfBags":
      case "bagWeight":
      case "moisture":
      case "waterActivity":
        return(
          <CurrencyTextField
            fullWidth
            size='small'
            type='text'
            inputMode='numeric'
            variant='outlined'
            textAlign='left'
            currencySymbol=''
            autoFocus={key === refInputData.current}
            value={info.renderValue()}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            decimalPlaces={0}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getSymbol(info.row.original.attribute)}
                </InputAdornment>)
            }}
          />
        )
      default:
        return(
          <TextField
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          />
        )
    }

  }

  const handlerPaperSize = (e) => {
    data[23]["value"] = e.target.value
    setPaperSize(e.target.value)
  }

  const columns = [
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <Box textAlign="left">{t('printLabel.includeInLabels')}</Box>,
      cell: renderEditStatus,
    }),
    columnHelper.accessor('attribute', {
      id: 'attribute',
      header: () => <Box textAlign="left">{t('printLabel.information')}</Box>,
      cell: info => t(`printLabel.${info.renderValue()}`) ,
    }),
    columnHelper.accessor('value', {
      id: 'value',
      header: () => <Box textAlign="left">{t('printLabel.data')}</Box>,
      cell: renderEditData,
    }),
  ]

  const table = useReactTable({
    data: cloneDeep(data.filter( x => x.attribute !== "paperSize")),
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handlerUpdate= (info, value) =>{
    let newArr = [...data];
    newArr[info.row.id][info.column.id] = value;
    setData([...newArr]);

    // if paper size DK
    if(paperSize === printLabelSize[1].key){
      if(data.filter(x => x.status === true).length >= 10){
          disableCheckboxes.length = 0
          data.filter( x => x.status === false).map( d => {
            disableCheckboxes.push(d.attribute)
          })
      } else {
        disableCheckboxes.length = 0
      }
    }
  }

	const renderContent = (
		<Box>
			<Grid container spacing={2} sx={{ mb: 3 , mt: 1}}>
				<Grid item xs={6}>
					<Typography sx={{mb: 1}}>{t('printLabel.title')}</Typography>
					<Typography sx={{ fontWeight: 200 }}>{t('printLabel.sharedInformationDescription')}</Typography>
				</Grid>
				<Grid item xs={6}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
							flexDirection: 'row',
							mb: 1
						}}
					>
						<Typography sx={{mr: 1}}>{t('printLabel.selectLabelSize')} </Typography>
						<LightTooltip
							arrow
							title={t('printLabel.selectLabelSizeTooltip')}
						>
							<HelpOutlineOutlined color='primary' />
						</LightTooltip>
					</Box>
					<Select
						fullWidth
						size='small'
						defaultValue=""
						value={paperSize}
						onChange={(e) => handlerPaperSize(e)}
					>
						{printLabelSize.map(item =>
							<MenuItem key={item.key} value={item.key}>{t(`options.${item.label}`)}</MenuItem>
						)}
					</Select>
				</Grid>
			</Grid>
			<Box sx={{borderRadius: 2, border: 1, p: 2, mt: 2, borderColor: colors.text.o25}}>
				<BasicTable table={table} />
			</Box>
			<Stack direction="row" spacing={2} sx={{mb: 1, mt: 4}}>
				<Button
					variant='contained'
					fullWidth
					onClick={() => {
						onSubmit(index, data)
						setOpenPrintLabel(true)
					}}
				>
					{t('shipment.button.submit')}
				</Button>
				<Button variant='outlined' fullWidth onClick={() =>{onClose()}}>
					{t('shipment.button.cancel')}
				</Button>
			</Stack>
		</Box>
	)

  React.useEffect(() => {
    if(isEmpty(JSON.parse(samplesToBeShipment[index].label))){
      setData(defaultData)
      setPaperSize('letter')
      return
    }

    let dataPrint = JSON.parse(samplesToBeShipment[index].label)
    setData(dataPrint)
    let findIndex = dataPrint.findIndex( d => d.attribute === "paperSize")
    setPaperSize(dataPrint[findIndex]["value"])
  }, [index])

  React.useEffect(() => {
    disableCheckboxes.length = 0
    setData(defaultData)
  }, [paperSize])

  return (
    <ModalWrapper
	    maxWidth={paperSize !== "letter" && openPrintLabel ? 'sm' : 'md'}
      open={open}
      {...openPrintLabel && {
				onClose: () => {
					setOpenPrintLabel(false)
	        onClose()
	      }
			}}
    >
	    {openPrintLabel ?
		    <PrintLabel dataLabels={[JSON.parse(samplesToBeShipment[index].label)]}/> :
		    renderContent
			}
    </ModalWrapper>
  )
})
