import React, {useRef} from "react";
import QRCode from "react-qr-code";
import {Box, Divider, Grid, Stack, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {isEmpty, truncate} from "lodash";
import {PrintOutlined} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {useReactToPrint} from "react-to-print";
import { GlobalStyles } from '@mui/material';

import {useStores} from "models";
import {printLabelSize} from "constants/form";
import {countries, optionsTranslation} from "utils"
import {colors} from "assets";
import miniLogo from 'assets/images/icons/tastify-mini-logo.svg'

import {PrintLabelProps} from "./print-label.props";

export const getValueLabel = (attribute, data) => {
  if (!data) return "-"

  switch (attribute) {
    case "sampleType":
      return optionsTranslation("sampleType", data)
    case "species":
      return optionsTranslation("species", data)
    case "moisture":
      return `${data} %`
    case "bagWeight":
      return `${data} kg`
    case "country":
      return countries.filter(x => x.code === data)[0]["name"]
    case "description":
      return truncate(data,{'length': 50, 'separator': ''})
    default:
      return data
  }
}

export const RenderLabel = (props) => {
  const {t} = useTranslation()
  const {dataLabel} = props

  return (
    <Grid container direction="row" spacing={2}>
      {!isEmpty(dataLabel) && dataLabel.filter(x => x.status === true && x.attribute !== "qrcode" && x.attribute !== "paperSize").map((data) => {
        return (
          <Grid item xs={6} key={data.attribute}>
            <Typography sx={{fontWeight: 600}}>{t(`printLabel.${data.attribute}`)}</Typography>
            <Typography sx={{maxWidth: 265, wordBreak: 'break-word'}}>{getValueLabel(data.attribute, data.value)}</Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}

export const PrintLabel: React.FC<PrintLabelProps> = observer((
	{ dataLabels, urlQrCode }: PrintLabelProps
) => {
  const {t} = useTranslation()
	const printElement = useRef(null)

  const {
    companyStore: { base64Logo, isEnterprisePlus }
  } = useStores()

  const [isPrinting, setIsPrinting] = React.useState(false)

	const firstDataLabel = dataLabels[0];
  const statusQrCode = firstDataLabel?.[0]?.status ?? false
	const paperSize = firstDataLabel?.find(x => x.attribute === "paperSize")?.value ?? printLabelSize[0].key;

	const paper = printLabelSize.filter(x => x.key === paperSize)[0]
  const paperWidth = paper["width"]
  const paperHeight = paper["height"]
	const paperPrintSize = paper["size"]

	const renderContent = React.useCallback(() => {
		return printElement.current;
	}, [printElement.current]);

	const handlePrint = useReactToPrint({
		content: renderContent,
		documentTitle: "label",
		onAfterPrint: () => setIsPrinting(false),
	});

  const RenderHeader = () => {
    let wd = 1
    if(isPrinting) wd = paperSize === "letter" ? 2: 5

    const url = urlQrCode || window.location.hostname + `/shipment-to-be-created?message=${encodeURIComponent('Shipment Has Yet to Be Created')}`

    return(
      <Stack
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="baseline"
        sx={{mt: 2}}
        width='100%'
      >
        {statusQrCode &&
          <QRCode
            size={200/wd}
            style={{width: "100%"}}
            value={url}
            viewBox={`0 0 256 256`}
          />
        }
        <Box sx={{ml: 3}}>
          <img src={isEnterprisePlus && base64Logo ? base64Logo :  miniLogo } height={200 / wd} width={200 / wd}  />
        </Box>
      </Stack>
    )
  }

  const RenderHeaderDk = () => {
    if(!statusQrCode) return null

    const url = urlQrCode || window.location.hostname + `/shipment-to-be-created?message=${encodeURIComponent('Shipment Has Yet to Be Created')}`

    return(
      <Box>
        <QRCode
	        size={75}
	        value={url}
	        viewBox={`0 0 75 75`}
        />
      </Box>
    )
  }

  const RenderTitleDk = (dataLabel) => {
    return (
      <Box flex={1}>
        {dataLabel?.filter(({ status, attribute }) => status && attribute !== "qrcode" && attribute !== "paperSize").map((data) => {
          return (
            <Box
              sx={{
								fontSize: 8,
                fontWeight: 'bold',
                letterSpacing: 1,
                lineHeight: 1.5,
                textAlign: 'center'
              }}
            >
              {t(`printLabel.${data.attribute}`)} : {getValueLabel(data.attribute, data.value)}
            </Box>
          )
        })}
      </Box>
    )
  }

  const RenderPrintPdf = () => {
    return(
      <Box
	      ref={printElement}
	      data-name='print-label'
	      sx={{
					border: isPrinting ? 0 : 1,
		      borderColor: colors.text.primary,
		      display: 'flex',
		      flexDirection: 'column',
		      alignItems: 'center',
				}}
      >
	      <GlobalStyles
		      styles={{
			      '@media print': {
				      '@page': {
					      size: `${paperPrintSize} !important`,
					      margin: 0,
				      },
			      },
		      }}
	      />

	      {dataLabels.map((dataLabel, index) => {
					if (paperSize === "dk" || paperSize === "dymo") {
						return (
							<>
							{index > 0 && <div style={{breakAfter:"page"}}/>}
								<Stack
									key={index}
									direction="column"
									alignItems="center"
									spacing={0.5}
									sx={{
										width: `${paperWidth}px`,
										height: `${paperHeight}px`,
										p: 1
									}}
								>
									<img src={isEnterprisePlus && base64Logo ? base64Logo : miniLogo } width={15} />
									{RenderTitleDk(dataLabel)}
									<RenderHeaderDk />
								</Stack>
							</>
						)
					}

					return (
						<>
							{index > 0 && <div style={{breakAfter:"page"}}/>}
							<Stack
								direction="column"
								justifyContent="space-around"
								alignItems="center"
								spacing={isPrinting ? 2 : 1}
								sx={{p: 5}}
							>
								<RenderHeader />
								<Divider sx={{mt: 1, mb: 1}}/>
								<RenderLabel dataLabel={dataLabel}/>
							</Stack>
		      </>
					)
	      })}
      </Box>
    )
  }

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

	  handlePrint()
  }, [isPrinting])

  return (
	  <Box>
      {RenderPrintPdf()}
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{mt: 3}}
      >
        <LoadingButton
          variant='text'
          color='primary'
          loadingPosition="start"
          startIcon={<PrintOutlined />}
          onClick={() => setIsPrinting(true)}
          loading={isPrinting}
        >
          <Typography variant='h6' color={colors.primary.main}>{t('shipment.button.printLabel')}</Typography>
        </LoadingButton>
      </Stack>
    </Box>
  )
})
