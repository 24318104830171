import {ceil, chain, isEmpty, snakeCase} from "lodash";
import {applySnapshot, flow, getSnapshot} from "mobx-state-tree";

import {ApiResult} from "services/api";
import {samplePreProcessor} from "models/sample";
import {snakeCasePayload} from "utils";
import {
  contractDetailPreProcessor,
  contractSalesContractPreProcessor,
  contractSamplePreProcessor,
  reviewSamplePreProcessor
} from "./contract.pre-processor";
import {DATA_PER_PAGE} from "config/env";

export const withContractActions = (self: any) => ({
  actions: {

    setContractDetail(data) {

      const newContractDetail = {
        ...contractDetailPreProcessor(data),
        aggregatedFlavorWheelGroups: JSON.stringify(data.aggregatedFlavorWheelGroups),
        aggregatedFlavorWheelOnlyFlavor: JSON.stringify(data.aggregatedFlavorWheelOnlyFlavor),
        aggregatedFlavorWheelOnlyFragrance: JSON.stringify(data.aggregatedFlavorWheelOnlyFragrance),
      }

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        contractDetails: newContractDetail
      })
    },
    getContracts: flow(function * (options?) {
      try {
				let search = {}
	      if (options) {
		      search = options.reduce((acc, curr) => {
								      acc[`q[${snakeCase(curr.id)}]`] = curr.value
								      return acc
							      }, {})
	      }

        const res: ApiResult = yield self.environment.contractApi.all({
          source: 'salesforce',
	        "size": DATA_PER_PAGE,
	        "page[number]": self.page,
	        ...search
        })

        if (res.kind === "ok") {
	        const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
	        self.setValue('totalPage', totalPage)

          return res.data.map((sample) => {
            return {
              ...samplePreProcessor(sample),
              id: sample.id,
              contractRemoteId: sample.contractRemoteId,
              receivedOn: sample.receivedAt ? new Date(sample.receivedAt) : null,
              source: sample.source,
            }
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getContractsDetail: flow(function * (id) {
      try {

        let additionalPath = `detail/review?purchase_contract_reference=${id}`
        const res: ApiResult = yield self.environment.contractApi.find(id, additionalPath)

        if (res.kind === "ok") {
            self.setContractDetail(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getSalesContractDetail: flow(function * (id) {
      try {

        let additionalPath = `detail/review?sales_contract_reference=${id}`
        const res: ApiResult = yield self.environment.contractApi.find(id, additionalPath)

        if (res.kind === "ok") {
            self.setContractDetail(res.data)

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getSalesContractSample: flow(function * (id) {
      try {

        let additionalPath = `detail/samples?sales_contract_reference=${id}`
        const res: ApiResult = yield self.environment.contractApi.find(id, additionalPath)

        if (res.kind === "ok") {
          const samples = res.data.map((sample: any) => contractSamplePreProcessor(sample))

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            samples
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getSample: flow(function * (id) {
      try {

        let additionalPath = `detail/samples?purchase_contract_reference=${id}`
        const res: ApiResult = yield self.environment.contractApi.find(id, additionalPath)

        if (res.kind === "ok") {
          const samples = res.data.map((sample: any) => contractSamplePreProcessor(sample))

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            samples
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getShipment: flow(function * (id) {
      try {

        let additionalPath = `detail/shipments?purchase_contract_reference=${id}`
        const res: ApiResult = yield self.environment.contractApi.find(id, additionalPath)

        if (res.kind === "ok") {

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            shipments: res.data
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getSalesContract: flow(function * (id) {
      try {

        let additionalPath = `detail/sales_contracts?purchase_contract_reference=${id}`
        const res: ApiResult = yield self.environment.contractApi.find(id, additionalPath)

        if (res.kind === "ok") {
          const salesContracts = res.data.map((data: any) => contractSalesContractPreProcessor(data))

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            salesContracts
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    createSamples: flow(function * (samples: any) {
      try {
        const additionalPath = 'bulk_create'
        const payload = {
          samples: samples.map((sample: any) => snakeCasePayload(sample))
        }
        yield self.environment.masterSampleApi.save(payload, {}, additionalPath)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    getSampleNonContract: flow(function * () {
      try {
        let search = {}
        chain(self.search)
          .omitBy(isEmpty)
          .assign({scoredByMe: self.search.scoredByMe, generalSearch: self.search.generalSearch})
          .forEach((value, key) => { search[`search[${snakeCase(key)}]`] = value})
          .value()


        const additionalPath = `samples_non_contract`
        const payload: any = {
          ...search,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }

        const res: ApiResult =
          yield self.environment.masterSampleApi.all(payload,additionalPath)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
          const hasNextPage = res.links && res.links.next
          const sampleNonContract = res.data.map((sample: any) => reviewSamplePreProcessor(sample))

          applySnapshot(self, {
            ...self,
            totalPage,
            sampleNonContract,
            records: res.meta.pagination.records,
            nextUrl: hasNextPage ? res.links.next : undefined,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    bulkSaveSamples: flow(function * (samples: any) {
      try {
        const additionalPath = 'bulk_update_contract'
        const payload = {
          samples: samples.map((sample: any) => snakeCasePayload(sample))
        }
        yield self.environment.masterSampleApi.save(payload, {}, additionalPath)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
  }
})
