/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import clsx from 'clsx'
import i18n from 'i18n/i18n'
import {debounce, replace} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom"
import {
  Box, Toolbar, AppBar, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText,
  IconButton, TextField, InputAdornment, Menu, MenuItem, SvgIcon, Tooltip,
} from '@mui/material'
import {
  Menu as MenuIcon, Search as SearchIcon, FlagOutlined as FlagOutlinedIcon, ArrowDropDownOutlined,
  ArrowDropUpOutlined, HelpOutlineOutlined, ShieldOutlined, InfoOutlined, PlayCircleOutline, ArticleOutlined, ContactSupportOutlined,
} from '@mui/icons-material'
import { isMobile } from "react-device-detect"

import { colors } from 'assets'
import { useStores } from "models"
import {Logo, MiniLogo, IconFeedback, IconAddSample, IconAddCuppingSession} from 'assets/images'
import { Profile, Setting } from 'pages/modal'
import {globalAction, listenGuideEnd} from 'utils'
import {REACT_APP_FEEDBACK_URL, JWT_STORAGE_KEY, WEB_APP_URL, BLOG_URL, GUIDE_URL} from 'config/env'
import {
  findRoute,
  sidebarMenu,
  dashboardRoutes,
  isAuthenticateRoutes,
  isResetPassword,
  sideMenuIcon,
  isInvitationRoutes,
  isPublicRoutes, sucafinaMenu
} from 'constants/routes'
import {Privacy, TermsOfUse, ServerError, Trial, Card, Welcome, NoConnection, TutorialNotification, RemoveAccount} from 'pages'
import {AvatarProfile, MaintenanceBanner, MenuIcon as MenuComponent} from 'components'
import { loadString } from "utils/storage"
import { showBannerBetaVersion } from 'config/setting'

import { useStyles, drawerWidth } from './dashboard-navigation.styles'

export const DashboardNavigation: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    reset: resetStore,
    userStore: { isAuthenticated, registrationCompleted, name, avatar, languagePreference, logout },
    companyStore: { name: companyName, unpaidSubscription, isTrialExpired, isKeurig, isDormantAccount, isSucafinaFeature },
    modalStore: { setValue: setModalValue },
    cuppingSessionStore: { setValue: setCuppingSessionValue },
    guideStore: { finishGuide },
    greenGradingStore: { getGreenGradingTypes },
    reviewSampleStore: { setValue: setReviewSampleValue },
    notificationStore
  } = useStores()

  const formCuppingSessionsLink = '/cupping-sessions/new'
  const homeLink = findRoute('home')
  const planLink = findRoute('plan')
  const signInLink = findRoute('signIn')
  const reviewSamplesLink = findRoute('reviewSamples')
  const tutorialLink = findRoute('tutorial')
  const watchVideoLink = findRoute('watchVideo')

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [miniDrawer, setMiniDrawer] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [anchorHelp, setAnchorHelp] = React.useState<null | HTMLElement>(null)
  const [anchorProfile, setAnchorProfile] = React.useState<null | HTMLElement>(null)
  const openHelp = Boolean(anchorHelp)
  const openProfile = Boolean(anchorProfile)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleMiniDrawer = () => {
    setMiniDrawer(!miniDrawer)
  }

  const onSearch = (e) => {
    if (e.key !== 'Enter') return

    navigate(`${reviewSamplesLink}?search=${search}`)
    setSearch('')
  }

  const onGetRequiredData = React.useCallback(() => {
    globalAction(notificationStore, {
      action: getGreenGradingTypes
    })
  }, [])

  const help = (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={(event) => setAnchorHelp(event.currentTarget)}
        className={classes.iconButton}
        sx={{ mr: { md: 10 }}}
      >
        <FlagOutlinedIcon />
        { openHelp ? <ArrowDropUpOutlined />  : <ArrowDropDownOutlined /> }
      </IconButton>

      <Menu
        anchorEl={anchorHelp}
        open={openHelp}
        onClose={() => setAnchorHelp(null)}
        onClick={() => setAnchorHelp(null)}
      >
        <MenuItem>
          <a
            href={REACT_APP_FEEDBACK_URL}
            target="_blank"
            rel="noreferrer"
            className='text'
          >
            <MenuComponent><SvgIcon component={IconFeedback} inheritViewBox /></MenuComponent>
            {t('menu.feedbackAndBugReports')}
          </a>
        </MenuItem>
        <MenuItem onClick={() => setModalValue('termsOfUse', true)}>
          <MenuComponent><InfoOutlined color='primary' /></MenuComponent>
          {t('menu.termsOfUse')}
        </MenuItem>
        <MenuItem onClick={() => setModalValue('privacy', true)}>
          <MenuComponent><ShieldOutlined color='primary' /></MenuComponent>
          {t('menu.privacy')}
        </MenuItem>
        <MenuItem onClick={() => navigate(watchVideoLink)}>
          <MenuComponent><PlayCircleOutline color='primary' /></MenuComponent>
          {t('menu.watchVideo')}
        </MenuItem>
      </Menu>
    </>
  )

  const profile = (rtl = true) => {
    return (
      <Box
        sx={{
          display: {xs: rtl ? 'none' : 'block', md: 'block'},
          cursor: 'pointer'
        }}
        data-testid='avatarProfile'
      >
        <AvatarProfile
          name={name}
          company={companyName}
          image={avatar}
          rtl={rtl}
          onClick={(event) => setAnchorProfile(event.currentTarget)}
        />

        <Menu
          anchorEl={anchorProfile}
          open={openProfile}
          onClose={() => setAnchorProfile(null)}
          onClick={() => setAnchorProfile(null)}
        >
          <MenuItem
            onClick={() => {
              setMobileOpen(false)
              setModalValue('profile', true)
            }}
          >
            {t('menu.myProfile')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMobileOpen(false)
              setModalValue('setting', true)
            }}
          >
            {t('menu.myProfileSettings')}
          </MenuItem>
          <MenuItem
            onClick={() => setModalValue('removeAccount', true)}
          >
            {t('menu.deleteMyAccount')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMobileOpen(false)
              logout()
              navigate(homeLink)
            }}
          >
            {t('menu.signOut')}
          </MenuItem>
        </Menu>
      </Box>
  )}

  const bannerBetaVersion = () => {
    if (showBannerBetaVersion) {
      return (
        <Box className={classes.banner}>
          {t('menu.betaVersionBanner')}
        </Box>
      )
    }
  }

  const bannerDormantAccount = () => {
    if (isDormantAccount) {
      return (
        <Box className={clsx(classes.banner, 'dormant')}>
          {t('menu.dormantAccountBanner')}
        </Box>
      )
    }
  }

  const navbarRight = (
    <Box className={classes.verticalCenter}>
      {!isMobile && bannerBetaVersion()}
      {!isMobile && bannerDormantAccount()}

      <Tooltip title={t('sample.button.createSample')}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => navigate('/samples/new')}
          className={classes.iconButton}
          sx={{ mr: { md: 10 }}}
        >
          <IconAddSample width={24} height={24} fill={colors.primary.main} />
        </IconButton>
      </Tooltip>

      {!isDormantAccount && (
        <Tooltip title={t('cuppingSession.button.createCuppingSession')}>
          <Link
            to={formCuppingSessionsLink}
            className={classes.verticalCenter}
            onClick={() => {
              setReviewSampleValue('cuppingSamples', [])
              setCuppingSessionValue('selectedCuppingSession', undefined)
            }}
          >
            <IconAddCuppingSession width={24} height={24} fill={colors.primary.main} />
          </Link>
        </Tooltip>
      )}

      <IconButton
        size="small"
        color="primary"
        onClick={() => navigate(tutorialLink)}
        className={classes.iconButton}
        sx={{ mr: { md: 10 }}}
      >
        <HelpOutlineOutlined />
      </IconButton>

      {help}
      {profile()}
    </Box>
  )

  const appBar = (
    <AppBar
      position="fixed"
      sx={{
        width: { md: miniDrawer ? '100%' : `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
      className={classes.appBar}
    >
      <MaintenanceBanner />
      <Toolbar className={classes.toolbar}>
        <Box sx={{ml: miniDrawer ? 7 : 0}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.iconButton}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <TextField
            aria-label='global search'
            placeholder={t('common.search')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className={classes.search}
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyPress={onSearch}
          />
        </Box>

        {navbarRight}
      </Toolbar>

      {isMobile && bannerBetaVersion()}
      {isMobile && bannerDormantAccount()}
    </AppBar>
  )

  const drawer = (isMobile = true) => {
    return (
      <>
        <Toolbar
          className={clsx(classes.toolbar, classes.toolbarDrawer)}
        >
          <Link to={homeLink} className={classes.logo} data-testid="linkLogo">
            { miniDrawer ? <MiniLogo width={20} height={20} /> : <Logo width={86} height={23} /> }
          </Link>

          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            className={classes.iconButton}
            onClick={isMobile ? handleDrawerToggle : handleMiniDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>

        {mobileOpen && (
          <Box sx={{px: 3}}>
            {profile(false)}
            <Divider textAlign='center' variant='middle' sx={{my: 2}} />
          </Box>
        )}

        <List sx={{px: miniDrawer ? 0 : 3}}>
          {sidebarMenu.map((menu, index) => {
            if (isDormantAccount && menu.name === 'cuppingSessions') return null
            if (!isSucafinaFeature && sucafinaMenu.includes(menu.name)) return null

            return (
              <Link to={menu.path} key={index} className='text' onClick={() => setMobileOpen(false)}>
                <ListItem
                  button
                  aria-label={`nav-${menu.name}`}
                  className={clsx(
                    classes.menuItem,
                    miniDrawer && classes.menuMiniDrawer,
                    location.pathname === menu.path && 'active'
                  )}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    { location.pathname === menu.path ? menu.iconFilled : menu.icon }
                  </ListItemIcon>
                  <ListItemText primary={t(`menu.${menu.name}`)} />
                </ListItem>
              </Link>
            )
          })}
        </List>
        <Divider textAlign='center' variant='middle' />
        <List sx={{px: miniDrawer ? 0 : 3}}>
          <a href={REACT_APP_FEEDBACK_URL} target="_blank" rel="noopener noreferrer" className='text'>
            <ListItem
              button
              className={clsx(
                classes.menuItem,
                miniDrawer && classes.menuMiniDrawer
              )}
            >
              <ListItemIcon className={classes.menuIcon}>
                { sideMenuIcon(miniDrawer, 'feedback') }
              </ListItemIcon>
              <ListItemText primary={t(`menu.feedbackAndBugReports`)} />
            </ListItem>
          </a>
          <ListItem
            button
            className={clsx(
              classes.menuItem,
              miniDrawer && classes.menuMiniDrawer
            )}
            onClick={() => {
              setMobileOpen(false)
              setModalValue('privacy', true)
            }}
          >
            <ListItemIcon className={classes.menuIcon}>
              { sideMenuIcon(miniDrawer, 'privacy') }
            </ListItemIcon>
            <ListItemText primary={t(`menu.privacy`)} />
          </ListItem>
          <ListItem
            button
            className={clsx(
              classes.menuItem,
              miniDrawer && classes.menuMiniDrawer
            )}
            onClick={() => {
              setMobileOpen(false)
              setModalValue('termsOfUse', true)
            }}
          >
            <ListItemIcon className={classes.menuIcon}>
              { sideMenuIcon(miniDrawer, 'termCondition') }
            </ListItemIcon>
            <ListItemText primary={t(`menu.termsOfUse`)} />
          </ListItem>
          <ListItem
            button
            className={clsx(
              classes.menuItem,
              miniDrawer && classes.menuMiniDrawer,
              location.pathname === tutorialLink && 'active'
            )}
            onClick={() => {
              setMobileOpen(false)
              navigate(tutorialLink)
            }}
          >
            <ListItemIcon className={classes.menuIcon}>
              { sideMenuIcon(location.pathname === tutorialLink, 'tutorial', miniDrawer) }
            </ListItemIcon>
            <ListItemText primary={t(`tutorial.helpAndTutorials`)} />
          </ListItem>
          <a href={BLOG_URL} target="_blank" rel="noopener noreferrer" className='text'>
            <ListItem
              button
              className={clsx(
                classes.menuItem,
                miniDrawer && classes.menuMiniDrawer
              )}
            >
              <ListItemIcon className={classes.menuIcon}>
                <ArticleOutlined color="primary" />
              </ListItemIcon>
              <ListItemText primary={t(`menu.blog`)} />
            </ListItem>
          </a>
          <a href={GUIDE_URL} target="_blank" rel="noopener noreferrer" className='text'>
            <ListItem
              button
              className={clsx(
                classes.menuItem,
                miniDrawer && classes.menuMiniDrawer
              )}
            >
              <ListItemIcon className={classes.menuIcon}>
                <ContactSupportOutlined color="primary" />
              </ListItemIcon>
              <ListItemText primary={t(`menu.howToGuide`)} />
            </ListItem>
          </a>
          <ListItem
            button
            className={clsx(
              classes.menuItem,
              miniDrawer && classes.menuMiniDrawer,
              location.pathname === watchVideoLink && 'active'
            )}
            onClick={() => {
              setMobileOpen(false)
              navigate(watchVideoLink)
            }}
          >
            <ListItemIcon className={classes.menuIcon}>
              { sideMenuIcon(location.pathname === watchVideoLink, 'play', miniDrawer) }
            </ListItemIcon>
            <ListItemText primary={t(`menu.watchVideo`)} />
          </ListItem>
        </List>
      </>
    )
  }

  React.useEffect(() => {
    i18n.changeLanguage(languagePreference)
  }, [languagePreference])

  React.useEffect(() => {
    if (isAuthenticated && registrationCompleted) {
      document.body.style.backgroundColor = colors.body.secondary
      setModalValue('serverError', false)
      onGetRequiredData()
      return
    }

    if (!isAuthenticateRoutes(location.pathname) && !isPublicRoutes(location.pathname)) {
      navigate(signInLink, { replace: true, state: { from: location }})
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    if (!isKeurig) return

    (async () => {
        const authorizationBearer = await loadString(JWT_STORAGE_KEY)
        if (!authorizationBearer) return

        if (authorizationBearer) {
          resetStore()
          window.location.href  = `${WEB_APP_URL}/users/sign_in?authorization=${replace(authorizationBearer, 'Bearer ', '')}`
          return
        }

        window.location.href = `${WEB_APP_URL}/users/sign_in`
    })()
  }, [isKeurig])

  React.useEffect(() => {
    if (isResetPassword(location.pathname)) {
      logout()
      navigate(location.pathname)
    }

    if (isInvitationRoutes(location.pathname)) {
      resetStore()
      navigate(location.pathname + location.search, { replace: true })
      return
    }

    listenGuideEnd((data) => finishGuide(data))
  }, [])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      if(window.innerWidth < 900) setMiniDrawer(false)
      if(window.innerWidth >= 900) setMobileOpen(false)
    })
  }, [])

  if (!isAuthenticated || !registrationCompleted || isKeurig || isPublicRoutes(location.pathname)) return null

  if ((unpaidSubscription || isTrialExpired) && location.pathname !== planLink) navigate(planLink)

  return (
    <Box sx={{ flexGrow: 1 }}>
      {appBar}

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ display: { sm: 'block', md: 'none' } }}
          className={classes.drawer}
          data-testid="mobileDrawer"
        >
          {drawer()}
        </Drawer>
        <Drawer
          open={miniDrawer}
          variant="permanent"
          sx={{ display: { xs: 'none', md: 'block' } }}
          className={clsx(classes.drawer, (miniDrawer && classes.miniDrawer))}
        >
          {drawer(false)}
        </Drawer>
      </Box>
      <Box className={clsx(classes.pageWrapper, (miniDrawer && 'miniDrawer'))}>
        <Routes>
          {dashboardRoutes.map((route, index) => {
            if (!isSucafinaFeature && sucafinaMenu.includes(route.name)) return null

            return (
              <Route key={index} path={route.path} element={route.element} />
            )
          })}

        </Routes>
      </Box>

      <Profile />
      <Setting />

      <Privacy />
      <TermsOfUse />

      <TutorialNotification />
      <Welcome />
      <Card />
      <Trial />
      <RemoveAccount />
      <ServerError />
      <NoConnection />
    </Box>
  )
})
