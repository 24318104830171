import React, {useEffect, useState} from 'react';
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataGrid, GridColDef, useGridApiRef} from "@mui/x-data-grid";
import {observer} from "mobx-react-lite";

import {useStores} from "models";
import {DataGridSelect, DataGridDescriptors} from "components";
import {
	simplifiedCommonSelections,
	simplifiedCommonExpectations,
	simplifiedCommonSampleResults
} from "constants/form";
import { flavorDescriptorList } from 'constants/flavor-descriptors'

import {useStyles} from "./simplified-common.styles";
import {EditSampleModal} from "./edit-sample-modal";

export const SimplifiedCommon = observer(() => {
	const { t } = useTranslation()
	const classes = useStyles()
	const apiRef = useGridApiRef();
	const [rows, setRows] = useState<any[]>(() => [])
	const [openEditSampleModal, setOpenEditSampleModal] = useState(false)

	const {
		sampleStore: { samples }
	} = useStores()

	const processRowUpdate = (newRow) => {
		setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));

		const sample = samples.find(s => s.id === newRow.id)
		if (!sample) return

		sample.selectedScore.setValue('totalScore', newRow.totalScore)
		sample.selectedScore.setValue('flavorDescriptors', newRow.flavorDescriptors)
		sample.selectedScore.setValue('acidityNote', newRow.acidityNote)
		sample.selectedScore.setValue('sweetnessNote', newRow.sweetnessNote)
		sample.selectedScore.setValue('physicalAspectDescription', newRow.physicalAspectDescription)
		sample.selectedScore.setValue('physicalAspectRate', newRow.physicalAspectRate)
		sample.selectedScore.setValue('overallNote', newRow.overallNote)
		sample.selectedScore.setValue('result', newRow.result)
	};

	const updateSampleReference = () => {
		setRows(prevRows => {
			const newRows = [...prevRows]
			newRows.forEach(row => {
				const sample = samples.find(s => s.id === row.id)
				if (!sample) return

				row.sampleReference = sample.sampleReference
			})

			return newRows
		})
	}

	const columns: GridColDef[] = [
		{ field: 'id' },
		{
			field: 'sampleReference',
			headerName: t('sample.sampleReference'),
			width: 150,
		},
		{
			field: 'totalScore',
			headerName: t('descriptor.score'),
			editable: true,
			type: 'number',
			width: 100,
		},
		{
			field: 'flavorDescriptors',
			headerName: t('descriptor.flavor'),
			type: 'singleSelect',
			valueOptions: flavorDescriptorList,
			editable: true,
			width: 400,
			renderEditCell: (params) => <DataGridDescriptors {...params} />,
		},
		{
			field: 'acidityNote',
			headerName: t('descriptor.acidity'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonSelections,
			width: 200,
			renderEditCell: (params) => <DataGridSelect {...params} />,
		},
		{
			field: 'sweetnessNote',
			headerName: t('descriptor.sweetness'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonSelections,
			renderEditCell: (params) => <DataGridSelect {...params} />,
			width: 200,
		},
		{
			field: 'physicalAspectDescription',
			headerName: t('descriptor.physicalAspectDescription'),
			editable: true,
			width: 200,
		},
		{
			field: 'physicalAspectRate',
			headerName: t('descriptor.physicalAspectRate'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonExpectations,
			renderEditCell: (params) => <DataGridSelect {...params} />,
			width: 200,
		},
		{
			field: 'overallNote',
			headerName: t('descriptor.overallExpectation'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonExpectations,
			renderEditCell: (params) => <DataGridSelect {...params} />,
			width: 200,
		},
		{
			field: 'result',
			headerName: t('descriptor.sampleResult'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonSampleResults,
			renderEditCell: (params) => <DataGridSelect {...params} />,
			width: 200,
		},
	]

	const handleCellKeyDown = (params, event) => {
		if (event.key === 'Tab') {
			event.preventDefault(); // Prevent the default tab behavior

			const { id: rowId, field } = params; // Current cell's row and field
			const columnIndex = columns.findIndex((col) => col.field === field);

			let nextColumnIndex;
			let nextRowId = rowId;

			if (event.shiftKey) {
				// Shift + Tab: Move to the previous cell
				nextColumnIndex = (columnIndex - 1 + columns.length) % columns.length;
				if (nextColumnIndex === columns.length - 1) {
					// Move to the previous row if we are at the first column
					nextRowId = rowId - 1 < rows[0].id ? rows[rows.length - 1].id : rowId - 1;
				}
			} else {
				// Tab: Move to the next cell
				nextColumnIndex = (columnIndex + 1) % columns.length;
				if (nextColumnIndex === 0) {
					// Move to the next row if we are at the last column
					nextRowId = rowId + 1 > rows.length ? rows[0].id : rowId + 1;
				}
			}

			const nextField = columns[nextColumnIndex].field;
			apiRef.current.setCellFocus(nextRowId, nextField);
		}
	};

	useEffect(() => {
		setRows(samples.map(sample => ({
			id: sample.id,
			sampleReference: sample.sampleReference,
			totalScore: sample.selectedScore.totalScore,
			flavorDescriptors: sample.selectedScore.flavorDescriptors,
			acidityNote: sample.selectedScore.acidityNote,
			sweetnessNote: sample.selectedScore.sweetnessNote,
			physicalAspectDescription: sample.selectedScore.physicalAspectDescription,
			physicalAspectRate: sample.selectedScore.physicalAspectRate,
			overallNote: sample.selectedScore.overallNote,
			result: sample.selectedScore.result,
		})))
	}, [])

	return (
		<Box mb={2}>
			<Box mb={2} textAlign="right">
				<Button variant='contained' onClick={() => setOpenEditSampleModal(true)}>
					{t('sample.button.editSample')}
				</Button>
			</Box>

			<DataGrid
				autoHeight
				rows={rows}
				columns={columns}
				processRowUpdate={processRowUpdate}
				hideFooter
				disableColumnMenu
				disableColumnSorting
				rowHeight={55}
				initialState={{
					columns: {
						columnVisibilityModel: {
							id: false,
						},
					},
				}}
				autosizeOptions={{
					includeHeaders: true,
					includeOutliers: true,
				}}
				className={classes.dataGrid}
				onProcessRowUpdateError={() => null}
				apiRef={apiRef}
				onCellKeyDown={handleCellKeyDown}
			/>

			<EditSampleModal
				open={openEditSampleModal}
				setOpen={setOpenEditSampleModal}
				updateSampleReference={updateSampleReference}
			/>
		</Box>
	);
});
